import { NgModule } from '@angular/core';
import { DwvModule } from './dwv/dwv.module';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [ AppComponent ],
  imports: [ 
    DwvModule, 
    HttpClientModule, 
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), 
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
