import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {

  constructor(
    private route: ActivatedRoute
  ){
    this.route.params.subscribe( params =>{
      console.log(params)
    });
  }
}
