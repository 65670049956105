import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DwvComponent } from './dwv/dwv.component';
import { AppComponent } from './app.component';


const routes: Routes = [
  { path: ':id', component: AppComponent  },
  // otras rutas
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
