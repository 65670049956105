<div>
  <mat-form-field class="searchfield">
    <input class="searchinput" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  <mat-slider class="instanceslider" id="instanceslider"
    [min]="min" [max]="max" step="1" showTickMarks="true" matTooltip="Instance number">
    <input class="instanceinput" matSliderThumb (input)="onsliderchange($event)">
  </mat-slider>
  <label for="instanceslider" class="instancelabel" matTooltip="Instance number">{{instanceNumber}}</label>

  <section class="container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4">
      <!-- name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <!-- value column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
        <td mat-cell *matCellDef="let element"> {{element.value}} </td>
      </ng-container>
      <!-- row template -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </section>
</div>
